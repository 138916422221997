import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

export default function Test({ data }) {
  const schemaMarkup = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "IlSalice EXTRA",
    url: "https://extra.ilsalice.org/",
    potentialAction: {
      "@type": "SearchAction",
      target: "{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>IlSalice EXTRA | Art and culture blog</title>
        <meta
          name="description"
          content="Articles and graphics on history, music, art, literature and culture in general."
        ></meta>
        <link rel="canonical" href="https://extra.ilsalice.org/"></link>
        <link
          rel="alternate"
          href="https://extra.ilsalice.org/it/"
          hreflang="it"
        ></link>
        <link
          rel="alternate"
          href="https://extra.ilsalice.org/"
          hreflang="en"
        ></link>
        <meta property="og:title" content="IlSalice EXTRA" />
        <meta
          property="og:description"
          content="Articles and graphics on history, music, art, literature and culture in general."
        />
        <meta
          property="og:image"
          content="https://extra.ilsalice.org/icons/ilsaliceEX.png"
        />
        <meta property="og:url" content="https://extra.ilsalice.org/" />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <GatsbyImage
        loading="lazy"
        image={getImage(data.enYaml.cover)}
      ></GatsbyImage>
      <img src="https://static.nike.com/a/images/t_PDP_864_v1/104a8040-6b02-4536-92b8-831196f659d7/elite-championship-8p-basketbal-hfqDgN.png" />
    </div>
  );
}

export const query = graphql`
  query MyQuery {
    enYaml {
      cover {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
